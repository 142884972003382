html, body {
  background-color: #F3F5F8;
}

.table-text-nowrap {
  white-space: nowrap !important;
}
.ant-tooltip.ant-tooltip-placement-top .ant-tooltip-arrow {
  height: 20px;
}
.page-layout .page-layout-all {
  background: #f2f6fc !important;
}
.page-layout .slider-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF !important;
}
.page-layout .slider-content .menu-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.menu-version {
  padding: 1em 0 1em 24px;
  color: #7E889B !important;
  font-size: 0.95em;
}
.page-layout .header-content {
  background: #fff !important;
  position: sticky;
  top: 0;
  z-index: 98;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0;
  padding-left: 30px;
  padding-right: 24px;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.page-layout .header-content .title {
    font-size: 1.3em;
    color: #2A61FF;
    margin-right: 2em;
}
.page-layout .header-content .logo-img {
  width: 88px;
  margin-right: 30px;
}
@media screen and (max-width: 992px) {
  .page-layout .header-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.page-layout .header-content .logo {
  font-size: 16px;
  margin-right: 20px;
}
@media screen and (min-width: 1400px) {
  .page-layout .header-content {
    padding-left: min(calc(50vw - 680px), 60px);
  }
  .page-layout .header-content .logo-img {
    margin-right: calc(220px - 88px - min(calc(50vw - 680px), 60px));
  }
  .page-layout .header-content .logo {
    margin-right: 30px;
  }
}
.page-layout .header-content .logo-img img {
  margin-bottom: 4px;
}
.page-layout .page-layout-content {
  background: #F3F5F8;
}
.page-layout .page-layout-content-content {
  background: #fff;
  padding: 10px 20px;
  margin-top: 14px;
  border-radius: 6px;
}
.sidebar-menu {
  padding-top: 20px;
  border-inline-end: none !important;
}
.sidebar-menu .ant-menu-item {
  margin: 5px 10px;
  border-radius: 4px;
  width: auto;
  color: rgba(0,0,0,0.45);
  height: 46px;
  background: #fff !important;
  transition: none !important;
}
.sidebar-menu .ant-menu-item .ant-menu-title-content {
  margin-left: 24px !important;
  font-size: 1.1em;
}
.sidebar-menu .ant-menu-item-icon {
  line-height: 46px !important;
  font-size: 1.45em !important;
  color: rgba(0,0,0,0.45);
  margin-bottom: -3px;
  transition: none !important;
}
.sidebar-menu .ant-menu-item a {
  color: rgba(0,0,0,0.65);
}
.sidebar-menu .ant-menu-item:hover a,
.sidebar-menu .ant-menu-item:hover .ant-menu-item-icon {
  color: #2A61FF;
}
.sidebar-menu .ant-menu-item::after {
  border-right: none !important;
}
.sidebar-menu .ant-menu-item-selected {
  background: rgba(42,97,255,0.08) !important;
  color: #2A61FF !important;
  font-weight: 500;
}
.sidebar-menu .ant-menu-item-selected a {
  color: #2A61FF !important;
  font-weight: 500;
}
.sidebar-menu .ant-menu-item-selected .ant-menu-item-icon {
  color: #2A61FF !important;
}
.sidebar-menu.ant-menu-inline-collapsed .ant-menu-item {
  padding: 0 calc(50% - 18px / 2 - 10px) !important;
  padding-top: 7px !important;
}
.page-layout .header-content .header-menu-vertical-trigger {
  display: none;
  align-items: center;
}
.page-layout .header-content .header-menu-vertical-trigger .menu-trigger {
  font-size: 1.5em;
  color: #717171;
}
.page-layout .layout-content-with-help {
  overflow: hidden;
  width: 100% !important;
  display: flex;
}
.page-layout .resize-box-content {
  height: calc(100vh - 60px);
  position: relative;
}
.page-simple-layout .page-layout-content,
.page-simple-layout .resize-box-content {
  height: 100vh;
}
.page-layout .resize-bar {
  height: inherit;
  opacity: 0;
  overflow: scroll;
  min-width: 50vw;
  resize: horizontal;
  cursor: col-resize;
}
.page-layout .resize-box-help {
  height: 100%;
  overflow-y: auto;
  flex: 1;
}
.page-layout .resize-real-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 2em;
  overflow-x: hidden;
}
.page-layout .dividing-line-horizontal {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background: #fff;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0,0,0,0.1);
  border-top: 0;
  border-bottom: 0;
  color: rgba(0,0,0,0.65);
}
.page-layout .dividing-line-horizontal span {
  writing-mode: vertical-rl;
  font-size: 14px;
  letter-spacing: 5px;
}
.page-layout .resize-bar:hover ~ .page-layout .dividing-line-horizontal,
.page-layout .resize-bar:active ~ .page-layout .dividing-line-horizontal {
  background: skyblue;
  color: #fff;
}
.page-layout .resize-bar::-webkit-scrollbar {
  width: 2em;
  height: inherit;
}
.page-layout .resize-box-help iframe {
  border: none;
  width: 100%;
  height: 100%;
}
.page-layout .ant-layout-sider {
  background: #fff;
}
.page-layout .layout-content-padding {
  padding: 14px 20px 20px 20px;
}
.page-layout .layout-content-without-help {
  max-width: 1400px;
  width: 100%;
  padding-top: 2em;
  background: transparent;
  overflow: auto;
  height: 100%;
}
.page-layout .header-button{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  min-width: 34px;
  height: 100%;
  background: #ffffff !important;
  border: none !important;
  cursor: pointer;
}
.page-layout .header-button:hover{
  background: #ffffff !important;
}
.page-layout .header-button-list {
  gap: 30px !important;
  height: 100%;
  display: flex;
}
.page-layout .header-button-list  .ant-space-item{
  height: 100%;
  display: flex;
  align-items: center;
}
.page-layout .header-content .split-line {
  margin: 0 1em;
  background: rgba(0,0,0,0.3);
  width: 1px;
  height: 1.5em;
}
.page-layout .horizontal-hidden {
  display: none;
}
.search-menu {
  width: 100%;
  box-shadow: none !important;
}
.search-menu .ant-menu-item {
  height: 1.8em;
  font-size: 1em;
}
.header-user-button {
  display: flex;
  align-items: center;
}
.header-user-button .ant-avatar {
  border: 1px solid #D9D9D9;
  background: #D9D9D9;
}
.header-user-button .header-user-name {
  max-width: 8em;
  margin-left: 10px;
  margin-right: 10px;
}
.common_pagetitle ol {
  justify-content: flex-end;
}
.common_pagetitle li .ant-breadcrumb-link span {
  color: rgba(0,0,0,0.45);
}
.common_pagetitle li .ant-breadcrumb-link a:hover span {
  color: #2A61FF;
}
.common_pagetitle li .ant-breadcrumb-link a {
  background-color: transparent;
}
.common_pagetitle li:last-child .ant-breadcrumb-link span {
  color: rgba(0,0,0,0.88);
}
@media screen and (max-width: 992px) {
  .page-layout .header-button-list {
    gap: 16px !important;
  }
  .page-layout .header-button{
    padding-left: 0px;
    padding-right: 0px;
  }
  .page-layout .header-button .ant-btn-icon {
    margin-inline-end: 0 !important;
  }
  .page-layout .header-content .logo-img {
    display: none;
  }
  .page-layout .header-content .title {
    display: none;
  }
  .page-layout .header-content .logo {
    margin-right: 5px;
  }
  .page-layout .header-content .header-menu-vertical-trigger {
    display: flex;
  }
  .page-layout .slider-horizontal {
    display: none !important;
  }
  .page-layout .vertical-hidden{
    display: none !important;
  }
  .page-layout .horizontal-hidden {
    display: inline-block;
  }
  .page-layout .layout-content-padding {
    padding: 8px 10px;
  }
  .page-layout .page-layout-content-content {
    background: #fff;
    margin-top: 8px;
    padding: 5px 10px;
  }
  .page-layout .layout-content-without-help {
    height: auto;
  }
  .page-layout .resize-bar,
  .page-layout .dividing-line-horizontal,
  .page-layout .resize-box-help {
    display: none;
  }
  .page-layout .resize-box-content, .page-layout .resize-real-content {
    width: 100%;
  }
}
.page-layout .ant-layout-sider-trigger {
  background: #fff;
  border-top: 1px solid rgba(0,0,0,0.06);
  color: #BFBFBF;
}


.bg-white {
  background: white !important;
}
.bg-gray {
  background-color: #fbfbfb !important;
}
.page-table {
  margin-bottom: 0px;
}
.page-table th {
  padding: 15px 10px;
  text-align: left;
}
.page-table td {
  padding: 10px;
  text-align: left !important;
}
.luckysheet-scrollbar-ltr {
  z-index: 999 !important;
}
.page-layout .luckysheet-loading-mask {
  z-index: 999;
}
.page-layout .luckysheet-bottom-controll-row {
  z-index: 989;
}
.ant-tabs-nav-list {
  z-index: 9999 !important;
}
.ant-tabs-tabpane{
  position: relative;
}
.tab-action {
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .tab-action {
    position: absolute;
    top:  -55px;
    right: 0;
  }
}
.log-search-form input {
  padding: 5.25px 12px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  font-size: 14px;
}
.log-search-form input:hover {
  border: 1px solid #2A61FF;
}
.log-search-form input::placeholder {
  color: rgba(0,0,0,0.3);
}
.log-search-form .pf-c-text-input-group__utilities {
  margin-left: 1em;
}
.home-guide .home-content-card-title,
.home-guide .menu-version {
  color: #FFF !important;
}
.home-guide .ant-collapse-content-box {
  padding: 20px !important;
}
.home-guide .ant-collapse-content-box .item {
  margin-right: 20px;
  width: 203px;
  flex: 0 0 auto;
}
.home-guide .ant-collapse-content-box .item .ant-card-body {
  padding: 8px !important;
}
.home-guide .ant-collapse-content-box .item:last-child {
  margin-right: 0;
}
.home-guide .ant-collapse-content-box .ant-card {
  border-radius: 7px;
  overflow: hidden;
  background: #F2F6FC;
  border: 1px solid rgba(0,0,0,0.05);
}
.home-guide .ant-collapse-header {
  background: #2A61FF;
  border-radius: 6px !important;
}
.home-guide .ant-collapse-item-active .ant-collapse-header{
  border-radius: 6px 6px 0 0 !important;
  padding: 16px 20px;
}
.home-guide .ant-collapse-content {
  background: #fff !important;
}
.home-guide {
  border-radius: 1em;
}
.home-content-card {
  padding: 8px;
  border-radius: 6px !important;
}
.home-app-card, .home-cover-card {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid rgba(0,0,0,0.1);
}
.home-app-card {
  padding: 6px 0;
}
.home-app-card .icon {
  padding: 4px 6px;
  background: rgba(42, 97, 255, 0.05);
  border-radius: 6px;
}
.home-app-card:hover {
  background: linear-gradient(328deg, #FFFFFF 0%, #EFF4FF 100%);
  border: 1px solid #2A61FF;
}
.home-cover-card .title-link {
  color: rgba(0,0,0,0.45) !important;
}
.home-cover-card .title-link:hover {
  text-decoration: underline;
  color: #2A61FF !important;
}
.header-workspace-manage {
  overflow: hidden;
  position: relative;
}
.header-workspace-manage .ant-dropdown-menu-item{
  padding: 10px 20px;
}
.header-workspace-manage .current-workspace {
  padding: 15px 12px 15px 18px;
  position: relative;
}
.header-workspace-manage .workspace-manage-list {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 100%;
  transform: translate(0, 0);
  transition: transform 0.5s;
  background: #fff;
  display: flex;
  flex-direction: column;
}
.header-workspace-manage .workspace-manage-list .header {
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-workspace-manage .workspace-manage-list .item {
  padding: 8px 10px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.header-workspace-manage .workspace-manage-list .item span {
  cursor: pointer;
}
.header-workspace-manage .workspace-manage-list .item:hover {
  background: rgba(0,0,0,0.05);
}
.instance-status-button {
  padding: 0 !important;
  background: transparent !important;
  border: none;
  box-shadow: none;
}
.instance-status-button:hover, .instance-status-button:focus {
  background: transparent;
  cursor: default;
}
.instance-status-button span {
  border-width: 2px;
  border-color: transparent !important;
  border-radius: 4px;
  margin-right: 0;
  cursor: default;
}
.instance-status-button-active:hover span .ant-tag-green {
  border-color: #b7eb8f !important;
}
.instance-status-button-active:hover span .ant-tag-red {
  border-color: #ffa39e !important;
}
.instance-status-button-active:hover span .ant-tag-gold {
  border-color: #ffe58f !important;
}
.instance-status-button-active:hover,
.instance-status-button-active:hover span {
  cursor: pointer !important;
}
.list-search-input {
  padding: 5.25px 12px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  font-size: 14px;
  max-width: 320px;
}

.chart-actions .ant-dropdown-menu-item {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

/* reset style */
.page-layout .ant-tabs-tab {
  color: rgba(0,0,0,0.65);
  cursor: pointer;
}
.page-layout .btn{
  box-shadow: none !important;
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 1em;
  border-radius: 4px;
  line-height: 1;
  padding: 4px 17px;
}
.page-layout .btn-outline-secondary {
  border-color: #D9D9D9;
}
.page-layout .btn-outline-secondary:hover {
  border-color: #4978FF;
  background: #fff;
  color: #2A61FF;
}
.page-layout .ant-pagination:not(.ant-pagination-mini) .ant-pagination-item,
.page-layout .ant-pagination:not(.ant-pagination-mini) .ant-pagination-item-link {
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  color: rgba(0,0,0,0.65);
}
.page-layout .ant-pagination:not(.ant-pagination-mini) .ant-pagination-item:hover,
.page-layout .ant-pagination:not(.ant-pagination-mini) .ant-pagination-item-link:hover,
.page-layout .ant-pagination:not(.ant-pagination-mini) .ant-pagination-item-active {
  border: 1px solid #2a61ff;
  color: #2a61ff;
  background-color: #fff;
}
.page-layout .ant-pagination:not(.ant-pagination-mini) .ant-pagination-disabled .ant-pagination-item-link {
  border: 1px solid #D9D9D9;
}
.page-layout .ant-pagination:not(.ant-pagination-mini) .ant-pagination-disabled .ant-pagination-item-link span {
  cursor: not-allowed;
}
.page-layout .ant-pagination .ant-pagination-item-active a {
  color: #2a61ff;
}
.page-layout .ant-pagination .ant-pagination-jump-next .ant-pagination-item-link,
.page-layout .ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link,
.page-layout .ant-pagination .ant-pagination-jump-next .ant-pagination-item-link:hover,
.page-layout .ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link:hover {
  border: none;
}

.page-layout .ant-table-wrapper td.ant-table-column-sort {
  background-color: transparent;
}
.page-layout table tr.ant-table-row-level-0:has(> td .ant-table-row-expand-icon-expanded){
  background: rgba(42,97,255,0.03);
}
.page-layout table tr.ant-table-row-level-0:has(> td .ant-table-row-expand-icon-expanded) td.ant-table-cell-fix-left {
  background: rgb(247,248,255);
}
.page-layout .ant-table-wrapper tr.ant-table-row:hover td,
.page-layout .ant-table-wrapper .ant-table-thead >tr>td,
.page-layout .ant-table-wrapper .ant-table-thead >tr>th,
.page-layout .ant-table-expanded-row .ant-table-thead th.ant-table-column-sort {
  background-color: #fafafa !important;
}
.page-layout .ant-table-wrapper .ant-table-thead > tr > th {
  color: rgba(0,0,0,0.85);
}
.page-layout .ant-table-wrapper .ant-table-tbody > tr > td {
  color: rgba(0,0,0,0.65);
}
.page-layout .ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected td {
  background-color: rgba(42,97,255,0.06);
}
.page-layout .ant-table-thead > tr > th::before {
  background-color: transparent !important;
}
.page-layout .ant-table-wrapper .ant-table-column-sorters {
  justify-content: flex-start ;
}
.page-layout .ant-table-wrapper .ant-table-column-sorters .ant-table-column-title {
  flex: none;
}
.page-layout .ant-table-wrapper .ant-table-column-sorter {
  margin-left: 10px;
}
.page-layout table tr .ant-table-expanded-row-fixed:has(table) {
  background: #fff;
  box-shadow: inset 0px 0px 8px 0px rgba(42,97,255,0.3);
  filter: blur(0px);
  padding: 7px min(5vw, 55px);
}
.page-layout table tr .ant-table-expanded-row-fixed .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th {
  background: #fff;
  padding: 14px 11px;
}
.page-layout table tr .ant-table-expanded-row-fixed .ant-table-container::after {
  box-shadow: none !important;
}
.page-layout .ant-table-expanded-row .ant-table-placeholder td {
  background: #fff;
}
.page-layout .table-responsive:not(:has(.ant-pagination)) tr:last-child td {
  border-bottom: 0;
}

.page-layout .ant-typography.ant-typography-secondary {
  color: rgba(0,0,0,0.65);
}

.page-layout .guide-cards .item {
  cursor: pointer;
}

.page-layout .full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  margin-top: 0px;
}
.page-layout .full-screen .instance-sheet-box {
  min-height: calc(100vh - 82px) !important;
}
.page-layout .full-screen-exit-btn {
  display: none;
  z-index: 99999;
}
.page-layout .full-screen .full-screen-exit-btn {
  display: block;
}
.page-layout #luckysheet-full-screen, .page-layout #luckysheet-exit-full-screen {
  position: absolute;
  right: 4px;
  transform: translate(0, -50%);
  top: 50%;
}
.page-layout #luckysheet-exit-full-screen {
  display: none;
}
.page-layout .full-screen #luckysheet-full-screen {
  display: none;
}
.page-layout .full-screen #luckysheet-exit-full-screen {
  display: block;
}

.payment-card {
  width: 400px;
  margin: 1em;
  background: #ffffff;
  padding: 1.5em 2em;
  border-radius: 10px;
  padding-top: 2em;
}
.payment-card .price-tip {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2em 0;
}
.payment-card .price-tip .ant-typography {
  margin: 0 3px !important;
  line-height: 1 !important;
}
.payment-card button {
  width: 100%;
  margin: 0.5em 0;
}
