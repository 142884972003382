body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden-select-options .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item {
  display: none !important;
}

.ant-upload-wrapper .ant-upload-drag {
  background-color: transparent;
  padding: 20px 10px;
}

.grey-link {
  color: rgba(0, 0, 0, 0.45);
}

.grey-link:hover {
  color: #2a61ff !important;
}

.ant-modal .ant-modal-content {
  padding: 0;
}

.ant-modal .ant-modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 0;
}

.ant-modal .ant-modal-body,
.ant-modal-confirm.ant-modal .ant-modal-body {
  padding: 16px 20px !important;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}

.ant-modal .ant-modal-footer {
  margin-top: 0;
  padding: 10px 20px;
  border-top: 1px solid #F0F0F0;
}

.full-label label {
  width: 100%;
}

.ant-form-vertical .full-label label::after {
  display: none;
}

.ant-tooltip ::selection {
  color: #2A61FF;
  background: transparent;
}

.description_tooltip.ant-tooltip .ant-tooltip-inner {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
  padding: 1em 1.2em;
  font-size: 0.95em;
}

.description_tooltip.ant-tooltip .ant-tooltip-arrow:before {
  background-color: #fff;
}

/*antd style*/
.ant-dropdown .ant-dropdown-menu {
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 8px 0;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  border-radius: 0;
  padding: 7px 14px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  color: #2A61FF;
  background: rgba(42, 97, 255, 0.06);
}

.ant-dropdown-menu .ant-dropdown-menu-item-divider {
  margin: 4px 14px !important;
}

/*luckysheet style*/
#luckysheet-rows-h {
  margin-top: -13px;
  padding-bottom: 0;
}
#luckysheet-icon-seachmore-menuButton .luckysheet-mousedown-cancel:not(:nth-child(-1n+2)){
  display: none;
}
.luckysheet-wa-editor {
  padding: 3px 0 4px 15px
}

#luckysheet-search-formula.luckysheet-modal-dialog #formulaTypeList {
  width: 100%;
}

#luckysheet-search-formula-parm.luckysheet-modal-dialog .parmListBox {
  width: 100%;
}

#luckysheet-search-formula-parm.luckysheet-modal-dialog .parmBox {
  display: flex;
}

#luckysheet-search-formula-parm .parmBox .txt input {
  background-color: transparent;
}

.luckysheet-freezebar-hover .luckysheet-freezebar-horizontal-drop-bar,
.luckysheet-freezebar-hover .luckysheet-freezebar-horizontal-drop-title {
  height: 2px !important;
  background-color: #5d88db;
}

.luckysheet-freezebar-hover .luckysheet-freezebar-vertical-drop-bar,
.luckysheet-freezebar-hover .luckysheet-freezebar-vertical-drop-title {
  width: 2px !important;
  background-color: #5d88db;
}

.luckysheet-freezebar-horizontal-drop-bar,
.luckysheet-freezebar-horizontal-drop-title {
  height: 1px !important;
  background-color: #1677ff !important;
}

.luckysheet-freezebar-vertical-drop-bar,
.luckysheet-freezebar-vertical-drop-title {
  width: 1px !important;
  background-color: #1677ff !important;
}

body.language-zh .luckysheet-cols-rows-shift-right,
body.language-zh .luckysheet-cols-rows-shift-left,
body.language-zh .luckysheet-cols-rows-shift-word {
  margin-left: -3px;
  margin-right: -3px;
}

#luckysheet-cols-rows-add input {
  margin-right: 5px;
}

.luckysheet-cols-menu,
.luckysheet-cols-menu-style {
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 4px;
  padding: 8px;
  background-color: #fff;
}

.luckysheet-cols-menu-style .ant-dropdown-menu {
  padding: 0;
}

#luckysheet-rightclick-menu {
  min-width: 150px;
}

#luckysheet-rightclick-menu .luckysheet-cols-menuitem .luckysheet-cols-menuitem-content,
#luckysheet-filter-menu .luckysheet-cols-menuitem-content {
  padding: 3px 0px 3px 1px;
}

.luckysheet-cols-menu .luckysheet-cols-menuitem,
.luckysheet-cols-menu-style .ant-dropdown-menu-item {
  padding: 6px 8px !important;
  font-size: 13px !important;
  border-radius: 4px;
}

.luckysheet-cols-menu .luckysheet-cols-menuitem .luckysheet-cols-menuitem-content {
  color: rgba(0, 0, 0, 0.75) !important;
}

.luckysheet-cols-menu .luckysheet-cols-menuitem:hover {
  background-color: #f5f5f5;
}

.luckysheet-cols-menu .luckysheet-cols-menuitem .luckysheet-submenu-arrow {
  margin-right: 10px;
  margin-top: 5px;
}

#luckysheet-icon-freezen-menu-menuButton .luckysheet-cols-menuitem .icon,
#luckysheet-icon-autofilter-menuButton .luckysheet-cols-menuitem .icon {
  display: none !important;
}

.luckysheet-cols-menu .luckysheet-menuseparator {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.luckysheet-cols-menu .luckysheet-filter-byvalue .ListBox {
  overflow-x: auto !important;
  margin: -10px 0 -7px 0;
}

#luckysheet-icon-textwrap-menu-menuButton.luckysheet-cols-menu {
  width: 160px !important;
}

.luckysheet-toolbar-menu-button {
  margin-right: 3px;
}

.luckysheet-toolbar-menu-button:hover {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

.luckysheet-toolbar-menu-button button:hover {
  background-color: transparent !important;
}

#luckysheet-freezen-btn-horizontal .luckysheet-toolbar-menu-button-caption {
  margin-right: 5px;
}

#luckysheet-sheet-list .luckysheet-cols-menuitem .luckysheet-cols-menuitem-content {
  padding-left: 0 !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

#luckysheet-sheet-list .luckysheet-cols-menuitem-content .icon {
  margin-left: 0;
  margin-right: 5px;
}

#luckysheet-filter-menu .luckysheet-submenu-arrow {
  margin-right: 0;
}

#luckysheet-filter-menu .ListBox input.luckysheet-mousedown-cancel {
  margin-right: 5px;
  margin-top: 1px;
  margin-bottom: 4px;
}

#luckysheet-filter-menu .ListBox label.luckysheet-mousedown-cancel,
#luckysheet-filter-menu .ListBox span.luckysheet-mousedown-cancel {
  margin-bottom: 0;
  font-size: 13px;
}

#luckysheet-filter-menu .luckysheet-mousedown-filter-byvalue-btn div,
#luckysheet-filter-menu div.luckysheet-filter-byvalue-input-icon {
  right: 3px;
}

#luckysheet-filter-menu #luckysheet-filter-byvalue-input {
  width: 100%;
  min-width: 190px;
}

#luckysheet-filter-menu .btn {
  font-size: 13px;
}

#luckysheet-search-replace .btnBox button {
  margin-left: 8px !important;
}

.fa-times:before {
  content: "\f00d";
}

.luckysheet-modal-dialog-buttons button {
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  margin: 3px 0 !important;
}

.luckysheet-modal-dialog-title-close .fa {
  font-size: 1.2em;
}

#luckysheet-search-replace .tabBox span {
  margin-right: 2px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #d9d9d9;
  border-bottom: none;
  padding: 4px 20px;
  width: 7em;
  margin-bottom: -1px;
}

#luckysheet-search-replace .tabBox span.on {
  color: #2a61ff;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-bottom: 1px solid #fff;
}

#luckysheet-search-replace .ctBox {
  padding: 17px 23px;
}

#luckysheet-search-replace .inputBox {
  height: auto;
}

#luckysheet-search-replace .inputBox .checkboxs input {
  margin-top: 1px !important;
  margin-right: 5px;
}

#luckysheet-search-replace .inputBox .checkboxs {
  height: auto;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  flex-wrap: wrap;
}

#luckysheet-search-replace .inputBox .checkboxs div {
  display: flex;
}

#luckysheet-search-replace .inputBox .textboxs {
  height: auto;
}

#luckysheet-search-replace .inputBox .textboxs input {
  width: 100%;
  margin: 5px 0;
  border: 1px solid #d9d9d9;
}

#luckysheet-search-replace .ctBox {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #d9d9d9;
}

.luckysheet-modal-dialog-mask {
  background-color: rgb(28, 29, 33);
}

.luckysheet-modal-dialog {
  padding: 20px 24px;
  min-width: min(calc(100% - 30px), 500px);
  border-radius: 6px;
  border: 1px solid rgba(28, 29, 33, 0.2);
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03),
    0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
}

.luckysheet-modal-dialog-content {
  min-width: 100% !important;
}

.luckysheet-modal-dialog-title-close {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.luckysheet-modal-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.luckysheet-modal-dialog-buttons button {
  margin-left: 8px !important;
  padding: 4px 15px;
}

.luckysheet-modal-dialog-title {
  margin-bottom: 28px;
}

.luckysheet-modal-dialog table {
  width: 100%;
}

#luckysheet-bottom-controll-row span {
  display: none;
}

#luckysheet-bottom-controll-row input {
  display: none;
}

#luckysheet-bottom-controll-row {
  width: 100%;
  z-index: 989 !important;
  display: flex;
}

#luckysheet-bottom-add-row {
  background-color: transparent;
  border: none !important;
  box-shadow: none;
  outline: none;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  margin-top: 0px;
}

#luckysheet-bottom-add-row:hover {
  color: rgba(0, 0, 0, 1);
}

.luckysheet-toolbar-button,
.luckysheet-toolbar-menu-button {
  font-size: 14px;
}

#luckysheet-sort-dialog .luckysheet-sort-dialog-additem {
  color: #2A61FF;
  text-decoration: none !important;
  font-size: 14px;
}

#luckysheet-sort-dialog table tr td {
  border-top: none;
  padding-left: 0;
  padding-right: 10px;
  padding-bottom: 10px;
  vertical-align: bottom;
}

#luckysheet-sort-dialog table tr td:nth-last-child(1) {
  width: 14px;
  padding: 0;
  padding-bottom: 16px;
}

.luckysheet-sort-modal {
  font-size: 14px;
}

#luckysheet-sort-dialog table tr td select {
  width: 100%;
  max-width: 100%;
  padding: 4px 6px;
  border: 1px solid #b9b9b9;
  color: #444444;
}

#luckysheet-sort-dialog .divider {
  display: none;
}

#luckysheet-dropCell-icon {
  background-color: #fff !important;
  padding: 4px 6px !important;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
#luckysheet-dropCell-icon:hover {
  background-color: rgba(73, 120, 255, 0.1) !important;
}

#luckysheet-dropCell-icon #icon_dropCell {
  background-image: url("../images/icons/icon_dropCell_new.png") !important;
  width: 28px !important;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.no-resize textarea {
  resize: none;
}

.no-disabled-style .ant-select-selector {
  color: rgba(0, 0, 0, 0.85) !important;
  background: #ffffff !important;
}

.compare-checkbox label {
  width: 100%;
  display: flex;
}

.compare-checkbox label span {
  display: inline-block;
  width: 100%;
  text-align: left;
}

.compare-checkbox label span.ant-checkbox-inner {
  width: 16px !important;
}

.compare-checkbox label .ant-checkbox {
  width: auto;
}

.compare-table tbody td.ant-table-cell {
  padding: 0 8px !important;
  height: 1.8em !important;
  border-bottom: none !important;
  background-color: transparent !important;
}

.compare-table th.ant-table-cell {
  border-bottom: none !important;
}

.compare-table tr.row-delete,
.compare-table tr.row-delete td {
  background-color: #ffebe9 !important;
}

.compare-table tr.row-new,
.compare-table tr.row-new td {
  background-color: #e6ffec !important;
}

.compare-table tr.row-changed,
.compare-table tr.row-changed td {
  background-color: #fffbe6 !important;
}

.compare-table .row-changed .cell-changed {
  background-color: #ffe58f !important;
}

.compare-table .row-changed .cell-new {
  background-color: rgb(82, 196, 26) !important;
}

.compare-table .row-changed .cell-delete {
  background-color: rgb(255, 77, 79) !important;
}

.compare-table .ant-table-cell {
  white-space: nowrap !important;
  min-width: 6em;
  border: none !important;
  border-start-start-radius: 0px !important;
}

.compare-table table {
  border-radius: 0px !important;
}

.compare-table thead td.ant-table-cell-fix-left {
  min-width: 30px;
  background-color: #fafafa !important;
}

.compare-table tbody td.ant-table-cell-fix-left {
  min-width: 30px;
  background-color: #ffffff !important;
}

.compare-table .ant-table-pagination {
  display: none;
}

.compare-table tr.row-delete.row-selected td {
  background-color: rgb(229, 211, 210) !important;
}

.compare-table tr.row-new.row-selected td {
  background-color: rgb(207, 229, 212) !important;
}

.compare-table tr.row-changed.row-selected td {
  background-color: rgb(228, 225, 206) !important;
}

.compare-new-table .ant-table-cell-fix-left {
  border-left: 1px solid #dddddd !important;
}

.select-chart-type .rc-virtual-list-holder-inner {
  flex-direction: row !important;
  flex-wrap: wrap;
  padding: 0 12px;
}

.select-chart-type .ant-select-item {
  padding: 5px 0;
}

.select-chart-type .ant-select-item-group {
  width: 100%;
  padding: 5px 6px;
}

.select-chart-type .ant-select-item-option {
  margin: 5px 6px;
  width: 6em;
  height: 6em;
  padding: 5px;
  border: 1px solid #f5f5f5;
}

.select-chart-type .ant-select-item-option-active {
  background: transparent;
}

.select-chart-type .ant-select-item-option:hover {
  background: transparent;
  border: 1px solid #e6f7ff;
}

.select-chart-type .ant-select-item-option-selected {
  background: transparent;
  border: 1px solid #1890ff !important;
}

.chart-view-list {
  margin-right: -1em;
}

.chart-view-list .chart-view-item {
  margin-right: 1em;
  margin-bottom: 1em;
  width: 100%;
  resize: both;
  overflow: auto;
  border-radius: 2px;
  height: calc(30vh + 6em);
  float: left;
  min-height: calc(30vh + 6em);
  max-width: calc(100% - 1em);
  min-width: calc(100% - 1em);
}

@media screen and (min-width: 992px) {
  .chart-view-list .chart-view-item {
    width: calc(50% - 1em);
    min-width: calc(50% - 1em);
  }
}

@media screen and (min-width: 1200px) {
  .chart-view-list .chart-view-item {
    width: calc(33.33% - 1em);
    min-width: calc(33.33% - 1em);
  }
}

.btn-primary {
  background-color: #2A61FF !important;
}

.btn-primary:hover {
  background-color: #4978FF !important;
  border-color: #4978FF !important;
}

.btn-outline-primary, .btn-outline-primary:hover {
  color: #2A61FF !important;
  border-color: #2A61FF !important;
  background: #fff !important;
}
.btn-outline-primary:hover, .btn-outline-secondary:hover {
  color: #4978FF !important;
  border-color: #4978FF !important;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #6c757d !important;
  border-color: #D9D9D9 !important;
}

.table-link {
  color: #2A61FF !important;
  text-decoration: none;
  background-color: transparent;
}

.table-link:hover,
.table-link:hover a {
  color: #4978FF !important;
  text-decoration: underline;
}

.title-link {
  color: #2A61FF !important;
  text-decoration: none;
  background-color: transparent;
}

.select-role .ant-select-selection-item {
  height: 32px !important;
}

.hidden-scrscrollbar::-webkit-scrollbar {
  display: none;
}

.ant-breadcrumb-link span {
  cursor: pointer;
}

button span,
a span {
  cursor: pointer;
}

.ant-pagination-options-size-changer .ant-select-selector {
  cursor: pointer !important;
}

.chart_view .ant-collapse-content-box {
  padding: 0 !important;
}

.chart_view .ant-collapse-header {
  padding: 0 0 16px 0 !important;
}

.chart_view .ant-collapse-expand-icon svg {
  transform: rotate(-90deg);
}

.chart_view .ant-slider {
  margin: 11px 10px 11px 0px;
}

.ant-dropdown-menu-item-disabled {
  background-color: transparent !important;
  color: #00000040 !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
}

.page-layout .notification-tab {
  display: flex;
}
.page-layout .notification-type-tab {
  padding-right: 20px;
  margin-top: 5px;
}
.page-layout .notification-type-tab .ant-tabs-tab-active {
  background: rgba(42, 97, 255, 0.06);
  border: none !important;
}
.page-layout .notification-type-tab .ant-tabs-ink-bar {
  display: none;
}
.page-layout .notification-tab .ant-tabs-content-holder {
  width: 20px;
}

.luckysheet {
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji' !important;
}